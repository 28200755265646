.github-para {
  padding: 20vh 15vh 5vh 15vh;
  text-align: center;
  margin: 0 20vh;
  color: rgb(190, 190, 190);
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 18px;
}
.gh-link {
  color: rgb(190, 190, 190);
  text-decoration: none;
}
.gh-link:hover {
  text-decoration: underline;
}
.projects-container {
  gap: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 35vh;
}
.project-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-radius: 5px;
  transition: border 0.2s ease;
  font-size: 14px;
  height: 150px;
  cursor: pointer;
  border: 2px solid #333333;
  background-color: #232323;
  box-shadow: 0 2px 3px #0000003d;
}
.project-card:hover {
  border: 2px solid #4c4c4c;
}
.project-name {
  text-align: center;
}
.project-card-name {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  color: rgb(190, 190, 190);
  padding-top: 30px;
}
.project-card-languages {
  display: flex;
  align-self: center;
  gap: 15px;
}
.project-icons {
  gap: 15px;
  display: flex;
  align-self: flex-end;
}
.icon {
  color: rgb(190, 190, 190);
}
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 95px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tech-stack {
  align-self: center;
}

.project-tech ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  gap: 15px;
  font-weight: 200;
  color: rgb(170, 170, 170);
  font-size: 16px;
  justify-content: center;
}

.project-tech li {
  display: inline-block; 
}

@media (max-width: 1200px) {
  .projects-container {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 15vh;
  }
  .project-card-name,
  .project-card-languages {
    font-size: 14px;
  }
  .github-para {
    padding: 20vh 0vh 5vh 0vh;
    text-align: center;
    margin: 0 15vh;
    font-size: 16px;
  }
  .project-icons {
    gap: 10px;
  }
  .contact-container {
    gap: 10vh;
    margin: 10vh 25vh 10vh 25vh;
  }
}

@media (max-width: 768px) {
  .projects-container {
    gap: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 5vh;
  }
  .project-card {
    padding: 15px;
  }
  .project-card-name {
    font-size: 12px;
  }
  .project-tech ul {
    gap: 5px;
    font-size: 12px;
  }
  .github-para {
    margin: 0 5vh;
  }
}

@media (max-width: 480px) {
  .github-para {
    padding: 10vh 0vh 5vh 0vh;
    font-size: 14px;
  }
  .project-icons {
    font-size: 11px;
  }
  .project-card-name {
    margin: 0;
  }
}
