
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.loading-bar {
  width: 80%;
  max-width: 400px;
  height: 20px;
  overflow: hidden;
  position: relative;
  border: 2px solid #333333;
  background-color: #232323;
  box-shadow: 0 2px 3px #0000003d;
}

.loading-progress {
  width: 0;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 0.1s ease-in-out; 
}

@media (max-width: 500px) {

  .loading-bar {
    width: 50%;
    max-width: 300px;
    height: 15px;
  }
}
