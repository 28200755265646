body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.nav-container {
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
}
.nav-card {
  display: flex;
  align-items: center;
  border-radius: 30px;
  transition: all 0.2s ease;
  position: relative;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px #0000001a;
}
.nav-card a {
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 17px;
  z-index: 2;
  padding: 18px 30px;
  margin: 0 5px;
  font-weight: 300;
  color: white;
  text-decoration: none;
}
.nav-card a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
}

.nav-card a.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
}

.nav-card.scrolled {
  background-color: rgba(114, 114, 114, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px #0000001a;
}

@media (max-width: 595px) {
  .nav-card a {
    font-size: 15px;
    padding: 18px 20px;
  }
}
@media (max-width: 480px) {
  .nav-card a {
    font-size: 14px;
    padding: 18px 15px;
  }
}
@media (max-width: 415px) {
  .nav-card a {
    font-size: 12px;
    padding: 18px 12px;
  }
}