.imessage {
  background-color: black;
  border: 2px solid rgba(34, 33, 33);
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  padding: 0.3rem 1.5rem;
  justify-content: center;
  border-radius: 0.2rem;
}

.imessage p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 75%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
  font-family: "SanFrancisco" !important;
}

.imessage p::before,
.imessage p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
  font-family: "SanFrancisco" !important;
}

p.from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
}

p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-me::after {
  background-color: black;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.email-link {
  text-decoration: none;
  font-family: "SanFrancisco" !important;
  color: white;
}
.email-link:hover {
  text-decoration: underline;
}

p[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

p.from-me ~ p.from-me {
  margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

p.from-me ~ p.from-me:last-child {
  margin-bottom: 0.5rem;
}

p.from-them {
  align-items: flex-start;
  background-color: rgba(34, 33, 33);
  color: white;
}

p.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid rgb(34, 33, 33);
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

p.from-them::after {
  /* background-color: #ebeaeb; */
  background-color: black;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

p[class^="from-"].emoji {
  background: none;
  font-size: 2.5rem;
}

p[class^="from-"].emoji::before {
  content: none;
}

.no-tail::before {
  display: none;
}

.margin-b_none {
  margin-bottom: 0 !important;
}

.margin-b_one {
  margin-bottom: 0.5rem !important;
}

.margin-t_one {
  margin-top: 1rem !important;
}
.link{
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.link:hover{
  text-decoration: underline;
}

@font-face {
  font-family: "SanFrancisco";
  src: url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff2")
      format("woff2"),
    url("https://cdn.rawgit.com/AllThingsSmitty/fonts/25983b71/SanFrancisco/sanfranciscodisplay-regular-webfont.woff")
      format("woff");
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  margin: 0;
}

.container {
  margin: 0 auto;
  max-width: 650px;
}

h1 {
  font-weight: normal;
  margin-bottom: 0.5rem;
}

h2 {
  border-bottom: 1px solid #ebeaeb;
  color: #666;
  font-weight: normal;
  margin-top: 0;
  padding-bottom: 1.5rem;
}

.comment {
  color: #222;
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  max-width: 100%;
  padding: 0;
}

@media screen and (max-width: 800px) {
  body {
    align-items: center;
  }

  .container {
    align-items: center;
    place-content: center;
    width: auto;
  }

  .imessage {
    background-color: black;
    border: 2px solid rgba(34, 33, 33);

    font-family: "SanFrancisco", sans-serif;
    font-size: 16px;
    max-width: 90%;
    margin: 0.5rem 0;
    padding: 0.3rem 1.5rem;
  }

  .imessage p {
    border-radius: 1.15rem;
    line-height: 1.5;
    max-width: 100%;
    padding: 0.5rem 0.875rem;
    word-wrap: break-word;
  }
  p.from-me {
    align-self: flex-end !important;
  }
  @media screen and (max-width: 800px) {
    .imessage {
      font-size: 14px;
    }

    .margin-b_one {
      margin-bottom: 0.25rem !important;
    }

    .margin-t_one {
      margin-top: 0.5rem !important;
    }
  }
}
