.footer-container {
  background-color: rgba(114, 114, 114, 0.2);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px #0000001a;
  color: rgb(170, 170, 170);
  padding: 0 0 !important;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sm-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20vh;
}

.footer-section h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.footer-section a {
  color: rgb(170, 170, 170);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #007bff;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.quick-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 2vh;
  margin: 0;
}

.quick-links li {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .footer-section h3,
  .footer-section a , 
  .footer-section p {
font-size: 15px;
  }
}
@media (max-width: 441px) {
  .footer-section h3,
  .footer-section a , 
  .footer-section p {
font-size: 11px;
  }
  .sm-links {
    gap: 10vh;
}
}